<template>
  <div
    v-if="
      getUser.role.slug === 'super-administrateur' || getUser.role.slug == 'business-developer-pro' "
  >
    <b-card title="STATISTIQUES PAR POINT FOCAL">
      <div
        v-if="isPointsFocauxListLoading"
        class="text-center text-info my-2"
      >
        <b-spinner
          variant="info"
          style="width: 3rem; height: 3rem;"
        />
      </div>

      <app-collapse
        v-else
        accordion
        type="margin"
      >
        <app-collapse-item
          v-for="(pf, index) in pointFocauxList"
          :key="index"
          :title="pf.responsable_name + ' / ' + pf.town_name"
          @collapse-click="getPointFocalKpis(pf.id)"
        >
          <b-row class="justify-content-center mx-1 mb-3">
            <b-col
              sm="9"
              xs="12"
            >
              <b-form-group>
                <h5>Filtrer par date</h5>
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  :config="config"
                />
              </b-form-group>
            </b-col>

            <b-col
              sm="3"
              xs="12"
              class="mt-2"
            >
              <b-button
                :disabled="isRangeStatisticsLoading || rangeDate == null"
                variant="primary"
                @click="
                  rangeDate = null;
                  filterIsActive = false;
                  getPointFocalKpis(pf.id);
                "
              >
                {{ isRangeStatisticsLoading ? "Chargement..." : "Effacer" }}
              </b-button>
            </b-col>
          </b-row>

          <div
            v-if="isPointFocalKpisLoading"
            class="text-center text-info my-2"
          >
            <b-spinner
              variant="info"
              style="width: 3rem; height: 3rem;"
            />
          </div>

          <b-row v-else>
            <b-col
              v-for="(item, index) in Object.entries(statisticsItems)"
              :key="index"
              lg="4"
              sm="6"
              style="height:max-content !important;"
            >

              <statistic-card-horizontal
                :icon="
                  item[0] === 'numberOfEmployeesDeployed' ? 'UserIcon' :
                  item[0] === 'numberOfEmployeesRegistered' ? 'UserIcon' :
                  item[0] === 'numberOfEmployeesValidated' ? 'UserCheckIcon' :
                  item[0] === 'numberOfEmployeesWhoQuitTheirJobs' ? 'UserMinusIcon' :
                  item[0] === 'numberOfRegisteredPros' ? 'UsersIcon' :
                  ''"
                color="info"
                :statistic="item[1].toLocaleString()"
                :statistic-title="
                  item[0] === 'numberOfEmployeesDeployed' ? 'Employé(s) déployé(s)' :
                  item[0] === 'numberOfEmployeesRegistered' ? 'Employé(s) enregistré(s)' :
                  item[0] === 'numberOfEmployeesValidated' ? 'Employé(s) validé(s)' :
                  item[0] === 'numberOfEmployeesWhoQuitTheirJobs' ? 'Employé(s) désisté(s)' :
                  item[0] === 'numberOfRegisteredPros' ? 'Pro(s) inscrit(s)' :
                  ''"
              />
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-card>
  </div>

  <div v-else>
    <not-authorized />
  </div>

</template>

<script>
import {
  BCard,
  BCardText,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BCol,
  BRow,
  BFormGroup,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  components: {
    BCard,
    BCardText,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCol,
    BRow,
    BFormGroup,
    flatPickr,
    BButton,
    BSpinner,
    AppCollapse,
    AppCollapseItem,
    StatisticCardHorizontal,
  },

  data() {
    return {
      rangeDate: null,
      filterIsActive: false,
      isRangeStatisticsLoading: false,
      config: { mode: 'range' },
      isPointFocalKpisLoading: true,
      statisticsItems: {},
      pointFocalId: null,
      pointFocauxList: null,
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters(['isPointsFocauxListLoading']),
    ...mapGetters('offerType', ['getPointFocaux']),

  },

  watch: {
    rangeDate(val) {
      if (val.split('to').length === 2) {
        this.applyGetRangeStatisticsAction(val)
      }
    },
  },

  created() {
    this.getPointFocalAction()
      .then(response => {
        this.pointFocauxList = response.data.data
      })
  },

  methods: {
    ...mapActions('statistics', [
      'getPointFocalStatisticsAction',
      'getPointFocalStatisticsByRangeAction',
    ]),

    ...mapActions('offerType', ['getPointFocalAction']),

    getPointFocalKpis(id) {
      this.pointFocalId = id
      this.rangeDate = null
      this.filterIsActive = false
      this.isPointFocalKpisLoading = true
      this.getPointFocalStatisticsAction(id).then(response => {
        this.isPointFocalKpisLoading = false
        // console.log(response)
        this.statisticsItems = response.data
      })
    },

    applyGetRangeStatisticsAction(val) {
      // alert(this.pointFocalId)
      const payload = {
        start_date: val.split('to')[0],
        end_date: val.split('to')[1],
      }
      this.isRangeStatisticsLoading = true
      this.isPointFocalKpisLoading = true
      this.getPointFocalStatisticsByRangeAction({
        pfId: this.pointFocalId,
        payload,
      })
        .then(response => {
          this.isRangeStatisticsLoading = false
          this.isPointFocalKpisLoading = false
          this.statisticsItems = response.data

          this.filterIsActive = true
        })
        .catch(error => {
          this.isRangeStatisticsLoading = false
          this.isPointFocalKpisLoading = false
          if (error.response.status !== 422) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: 'Erreur lors du filtrage, Réessayer!',
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Erreur',
                  icon: 'AlertTriangleIcon',
                  text: error.response.data.message,
                  variant: 'danger',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
