var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.getUser.role.slug === 'super-administrateur' || _vm.getUser.role.slug == 'business-developer-pro' )?_c('div',[_c('b-card',{attrs:{"title":"STATISTIQUES PAR POINT FOCAL"}},[(_vm.isPointsFocauxListLoading)?_c('div',{staticClass:"text-center text-info my-2"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"info"}})],1):_c('app-collapse',{attrs:{"accordion":"","type":"margin"}},_vm._l((_vm.pointFocauxList),function(pf,index){return _c('app-collapse-item',{key:index,attrs:{"title":pf.responsable_name + ' / ' + pf.town_name},on:{"collapse-click":function($event){return _vm.getPointFocalKpis(pf.id)}}},[_c('b-row',{staticClass:"justify-content-center mx-1 mb-3"},[_c('b-col',{attrs:{"sm":"9","xs":"12"}},[_c('b-form-group',[_c('h5',[_vm._v("Filtrer par date")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.config},model:{value:(_vm.rangeDate),callback:function ($$v) {_vm.rangeDate=$$v},expression:"rangeDate"}})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"sm":"3","xs":"12"}},[_c('b-button',{attrs:{"disabled":_vm.isRangeStatisticsLoading || _vm.rangeDate == null,"variant":"primary"},on:{"click":function($event){_vm.rangeDate = null;
                _vm.filterIsActive = false;
                _vm.getPointFocalKpis(pf.id);}}},[_vm._v(" "+_vm._s(_vm.isRangeStatisticsLoading ? "Chargement..." : "Effacer")+" ")])],1)],1),(_vm.isPointFocalKpisLoading)?_c('div',{staticClass:"text-center text-info my-2"},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"info"}})],1):_c('b-row',_vm._l((Object.entries(_vm.statisticsItems)),function(item,index){return _c('b-col',{key:index,staticStyle:{"height":"max-content !important"},attrs:{"lg":"4","sm":"6"}},[_c('statistic-card-horizontal',{attrs:{"icon":item[0] === 'numberOfEmployeesDeployed' ? 'UserIcon' :
                item[0] === 'numberOfEmployeesRegistered' ? 'UserIcon' :
                item[0] === 'numberOfEmployeesValidated' ? 'UserCheckIcon' :
                item[0] === 'numberOfEmployeesWhoQuitTheirJobs' ? 'UserMinusIcon' :
                item[0] === 'numberOfRegisteredPros' ? 'UsersIcon' :
                '',"color":"info","statistic":item[1].toLocaleString(),"statistic-title":item[0] === 'numberOfEmployeesDeployed' ? 'Employé(s) déployé(s)' :
                item[0] === 'numberOfEmployeesRegistered' ? 'Employé(s) enregistré(s)' :
                item[0] === 'numberOfEmployeesValidated' ? 'Employé(s) validé(s)' :
                item[0] === 'numberOfEmployeesWhoQuitTheirJobs' ? 'Employé(s) désisté(s)' :
                item[0] === 'numberOfRegisteredPros' ? 'Pro(s) inscrit(s)' :
                ''}})],1)}),1)],1)}),1)],1)],1):_c('div',[_c('not-authorized')],1)}
var staticRenderFns = []

export { render, staticRenderFns }